// UofTResources.js
import React from 'react';
import AnimatedPage from '../animations/transitions';

function UofTResources() {
    const textStyle =  {
        color: 'gray',
    }
  
    return (
    <AnimatedPage>
      <div style={{ marginTop: '75px' }}></div>

      <p>
        <div style={{color: 'white'}}>
        This page is meant for my great friends back at UofT
        <br></br>
        <br></br>
      I figure its best to have as much information for everyone to learn from for free. All of these links are websites I found which pertain to courses at UofT. 
        If you have found any more, let me know and Il add them to this website.
        As well, I might pair these up with historical averages. If you have data related to this, please let me know. Also if you think you know of a better 
        colour for the links, send me an email. Im not sure im entirely happy with the gray. 
      </div>
        </p>

        <h2 style={{color: 'white'}}>These sites are for Mathematics</h2>
        <hr />
      <a href="https://mcs.utm.utoronto.ca/~108s21/index.html" target =" blank" style={textStyle}>
            CSC108 (Intro to Computer Programming) <br></br>
        </a>
        <a href="https://mcs.utm.utoronto.ca/~207/index.html" target =" blank" style={textStyle}>
            CSC207 (Software Design) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~shaharry/csc236/" target =" blank" style={textStyle}>
            CSC236 (Introduction to the Theory of Computation)<br></br>
        </a>
        <a href="http://www.cs.utoronto.ca/~trebla/CSCB09-2023-Summer/" target =" blank" style={textStyle}>
            CSCB09
        </a>
        <a href="https://mcs.utm.utoronto.ca/~209/23s/index.shtml" target =" blank" style={textStyle}>
            CSC209 <br></br>
        </a>
        <a href="https://mcs.utm.utoronto.ca/~301/" target =" blank" style={textStyle}>
            CSC301 (Software Engineering)<br></br>
        </a>
        <a href="http://www.cs.toronto.edu/~sme/CSC302/" target =" blank" style={textStyle}>
            CSC302 (Software Engineering II)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~mashiyat/csc309/index.htm#top" target =" blank" style={textStyle}>
            CSC309 (Web programming in 2016) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~kianoosh/courses/csc309/" target =" blank" style={textStyle}>
            CSC309 (Web programming in 2022) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~rgrosse/courses/csc311_f21/" target =" blank" style={textStyle}>
            CSC311 (Machine Learning) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~lczhang/csc338_20191/" target =" blank" style={textStyle}>
            CSC338 (Numerical Methods) <br></br>
        </a>
        <a href="https://mcs.utm.utoronto.ca/~358/" target =" blank" style={textStyle}>
            CSC358 (Computer Networks) <br></br>
        </a>
        <a href="https://www.cs.utoronto.ca/~fidler/teaching/2021/CSC420.html" target =" blank" style={textStyle}>
            CSC420 (Image Understanding) <br></br>
        </a>
        <a href="https://mcs.utm.utoronto.ca/~338/" target =" blank" style={textStyle}>
            CSC338 (Numerical Methods)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~florian/courses/csc477_fall22/" target =" blank" style={textStyle}>
            CSC477 (Mobile Robotics) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~lyan/csc384/w2/csc384ch3search1.pdf" target =" blank" style={textStyle}>
            CSC384 (Introduction to Artificial Inteligence) <br></br>
        </a>
        <a href="https://utsc.utoronto.ca/~atafliovich/cscc24/ " target =" blank" style={textStyle}>
            CSCC24 
        </a>
        <a href="https://www.cs.utoronto.ca/~trebla/CSCC24-2023-Summer/" target =" blank" style={textStyle}>
            CSCC24 <br></br>
        </a>
        <a href="https://www.cs.utoronto.ca/~elias/csc404/" target =" blank" style={textStyle}>
            CSC404 (Intro to Video Game Design) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~nisarg/teaching/304f22/" target =" blank" style={textStyle}>
            CSC304 (Algorithmic Game Theory)<br></br>
        </a>
        <a href="https://www.teach.cs.toronto.edu/~csc369h/summer/content.shtml" target =" blank" style={textStyle}>
            CSC369 (Operating Systems)<br></br>
        </a>
        <a href="https://www.teach.cs.toronto.edu/~csc411h/winter/" target =" blank" style={textStyle}>
            CSC411 (Machine Learning and Data Mining)<br></br>
        </a>
	<a href="https://github.com/alecjacobson/geometry-processing" target =" blank" style={textStyle}>
            CSC419 (Geometric Processing)<br></br>
        </a>
	<a href="https://github.com/alecjacobson/geometry-processing-csc2520" target =" blank" style={textStyle}>
            CSC2520 (Geometric Processing [Grad Course Version])<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~frank/csc2501/" target =" blank" style={textStyle}>
            CSC485 (Computational Linguistics)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~david/course-notes/csc110-111/" target =" blank" style={textStyle}>
            CSC110/CSC111 (Foundations of Computer Science)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~hehner/465-2104/" target =" blank" style={textStyle}>
            CSC465 (Formal Methods in Software Design)<br></br>
        </a>
        <a href="http://www.cs.toronto.edu/~chechik/courses18/csc2125/" target =" blank" style={textStyle}>
            CSC2125 (Modeling Methods, Tools and Techniques)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~florian/courses/csc2626w21/" target =" blank" style={textStyle}>
            CSC2626 (Imitation Learning for Robotics)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~yganjali/courses/csc458/" target =" blank" style={textStyle}>
            CSC458 (Computer Networks)<br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~nisarg/teaching/373f21/" target =" blank" style={textStyle}>
            CSC373 (Algorithm Design, Analysis, and Complexity) <br></br>
        </a>
        

        <h2 style={{color: 'white'}}>These sites are for Mathematics</h2>
        <hr />
        <a href="http://www.math.toronto.edu/ilia/Teaching/MAT406.2019/index.html" target =" blank" style={textStyle}>
            MAT406 (Game Theory) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/laithy/137/137.html" target =" blank" style={textStyle}>
            MAT137 (Calculus with Proofs) <br></br>
        </a>
        <a href="http://www.math.toronto.edu/courses/mat237y1/20199/" target =" blank" style={textStyle}>
            MAT237 (Multivariable Calculus with Proofs) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/nhoell/MAT224/" target =" blank" style={textStyle}>
            MAT224 (Advanced Linear Algebra) <br></br>
        </a>
        <a href="http://www.math.utoronto.ca/payman/mat247/main.html" target =" blank" style={textStyle}>
            MAT247 (Advanced Algebra) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/ilia/Teaching/MAT315.2011/index.html" target =" blank" style={textStyle}>
            MAT315 (Number Theory) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/~herzig/347-f19.html" target =" blank" style={textStyle}>
            MAT347 (Groups, fields, and rings) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/laithy/3272023/index.html" target =" blank" style={textStyle}>
            MAT327 (Topology) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/laithy/3672021/index.html" target =" blank" style={textStyle}>
            MAT367 (Differential Geometry) <br></br>
        </a>
        <a href="https://www.cs.toronto.edu/~vinodv/COURSES/MAT302-S13/index.html" target =" blank" style={textStyle}>
            MAT302 (Introduction to Algebraic Cryptography) <br></br>
        </a>
	    <a href="https://github.com/siefkenj/2023-MAT-335-webpage" target =" blank" style={textStyle}>
            MAT335 (Chaos Theory 2023) <br></br>
        </a>
        <a href="https://www.math.toronto.edu/afenyes/teaching/mat335-winter2019/" target =" blank" style={textStyle}>
            MAT335 (Chaos Theory 2019) <br></br>
        </a>



        <h2 style={{color: 'white'}}>These sites are for Physics</h2>
        <hr />
        <a href="https://www.physics.utoronto.ca/apl/405/index.html" target =" blank" style={textStyle}>
            PHY405 (Electronics Lab) <br></br>
        </a>
        <a href="https://ugdemos.physics.utoronto.ca/db/courses/phy250" target =" blank" style={textStyle}>
            PHY250 (Electromagnatism) <br></br>
        </a>
        <a href="https://www.physics.utoronto.ca/~william/courses/phy293/" target =" blank" style={textStyle}>
            PHY293 (Waves and Vibrations) <br></br>
        </a>


        <h2 style={{color: 'white'}}>These sites are for Statistics</h2>
        <hr />
        <a href="https://mcs.utm.utoronto.ca/~nosedal/258.html" target =" blank" style={textStyle}>
            STA258 (Probability and Statistics 2) <br></br>
        </a>
        <a href="https://mcs.utm.utoronto.ca/~rosenbl6/sta246/22f/index.shtml" target =" blank" style={textStyle}>
            STA246 (Computational Probability) <br></br>
        </a>
        <a href="https://utstat.toronto.edu/~brunner/oldclass/256f19/" target =" blank" style={textStyle}>
            STA256 (Probability and Statistics 1) <br></br>
        </a>
        <a href="http://www.individual.utoronto.ca/ranodya/indexChap1.html" target =" blank" style={textStyle}>
            STA107 (Intro to Probability) <br></br>
        </a>

        <h2 style={{color: 'white'}}>These sites are for Engineering</h2>
        <hr />
        <a href="https://eyolfson.com/courses/archive/utoronto/ece344/22fall/" target =" blank" style={textStyle}>
            ECE344 (Operating Systems) <br></br>
        </a>
        <a href="https://eyolfson.com/courses/archive/utoronto/ece353/2023-winter/" target =" blank" style={textStyle}>
            ECE353 (Systems Software) <br></br>
        </a>
        <a href="https://eyolfson.com/courses/archive/uwaterloo/ece459/12winter/" target =" blank" style={textStyle}>
            ECE459 (Programming for Performance) <br></br>
        </a>



    </AnimatedPage>
  );
}

export default UofTResources;
